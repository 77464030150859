
import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import "@aws-amplify/ui-react/styles.css";
import {
fetchUserAttributes
} from 'aws-amplify/auth';
import 'react-calendar/dist/Calendar.css';
import 'react-date-picker/dist/DatePicker.css';
import { AgGridReact } from 'ag-grid-react'; // React Grid Logic
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "./App.css"
import App from './App';
import AdminApp from './AdminApp';

const baseClassName = 'main';

function Main(props) {

  const [userAttributes, setUserAttributes] = useState({});
  const loaded = React.useRef(false);

  useEffect(() => {
    document.title = 'Submit Your Claim';
  }, []);

  useEffect(() => {
    const getUserAttributes = async () => {
        let attributes = await fetchUserAttributes();
        setUserAttributes(attributes) 
        loaded.current = true;
    }
    getUserAttributes();
  }, []);

  const renderPage = () => {
    if (userAttributes['custom:isAdmin'] === 'true') {
        return <AdminApp userAttributes user={props.user} signOut={props.signOut} />
    } else if (loaded.current) {
      return (<App userAttributes user={props.user} signOut={props.signOut} />) 
    }
       
  }

  



  return (
    <BrowserRouter>
        {renderPage()}
    </BrowserRouter>

  );
}

export default Main;
