import React, { useTheme } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Main from './Main';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import config from './aws-exports';
import {
  Authenticator, Image, View, Text
} from "@aws-amplify/ui-react";
import logo from './logo_color.png';

Amplify.configure(config);

const root = ReactDOM.createRoot(document.getElementById('root'));

const components = {
  Header() {
    

    return (
      <View textAlign="center" padding={15}>

        <Image
          alt="Amplify logo"
          src={logo}
        />

        <Text color={"gray"} fontSize={24}>
          Welcome to <b>SubmitYourClaim.net</b>.  
        </Text> 
        
        <Text>
          First time here? Click the <b>Create Account</b> tab below to get started submitting your claim!
        </Text>
      </View>
    );
  },

  Footer() {
   

    return (
      <View textAlign="center" padding={15}>
        <Text color={"gray"}>
          &copy; All Rights Reserved
        </Text>
      </View>
    );
  },
}

root.render(
  <React.StrictMode>
    <Authenticator components={components}>
    {({ signOut, user }) => (
    <Main user={user} signOut={signOut} />
    )} 
    </Authenticator>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
