import CircularProgress from "@mui/material/CircularProgress";
import logo from './logo_color.png';
import roofprotection from './images/roofprotection.png'
import moldsafe from './images/moldsafe.png'
import sewergard from './images/sewergard.png'
import nxtstructural from './images/nxtstructural.png'
import day125 from './images/125day.png'
import warranty from './images/warranty.jpg'
import React, { useEffect, useRef, useState } from 'react';
import { Link, BrowserRouter } from 'react-router-dom';
import "@aws-amplify/ui-react/styles.css";
import Modal from 'react-modal';
import { debounce } from '@mui/material/utils';
import parse from 'autosuggest-highlight/parse';
import {
  withAuthenticator,
  Button,
  View,
  Authenticator
} from "@aws-amplify/ui-react";
import {
  updateUserAttributes, getCurrentUser, fetchUserAttributes
} from 'aws-amplify/auth';
//import { Loader } from "@googlemaps/js-api-loader"
import Box from '@mui/material/Box';
import AutoComplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import DatePicker from 'react-date-picker'
import 'react-calendar/dist/Calendar.css';
import 'react-date-picker/dist/DatePicker.css';
import { AgGridReact } from 'ag-grid-react'; // React Grid Logic
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
//import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import "./App.css"
import { StorageManager } from '@aws-amplify/ui-react-storage'
import axios from 'axios'
import { Container, Row, Col } from 'react-grid';

const baseClassName = 'main';

function App(props) {

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [firstNameForContactUs, setFirstNameForContactUs] = useState('');
  const [lastNameForContactUs, setLastNameForContactUs] = useState('');
  const [phoneNumberForContactUs, setPhoneNumberForContactUs] = useState('');
  const [emailForContactUs, setEmailForContactUs] = useState('');
  const [reasonForContactUs, setReasonForContactUs] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [profileModalIsOpen, setProfileModalIsOpen] = useState(false);
  const [contactUsModalIsOpen, setContactUsModalIsOpen] = useState(false);
  const [claimModalIsOpen, setClaimModalIsOpen] = useState(false);
  const [claimNumber, setClaimNumber] = useState('');
  const [claimDescription, setClaimDescription] = useState('');
  const [claimIssue, setClaimIssue] = useState('');
  const [claimIssueOther, setClaimIssueOther] = useState('');
  const [inspectionDate, setInspectionDate] = useState('');
  const [closingDate, setClosingDate] = useState('');
  const [oneYearWarranty, setOneYearWarranty] = useState(false);
  const [homeOccupied, setHomeOccupied] = useState(false);
  const [bankOwned, setBankOwned] = useState(false);
  const [soldAsIs, setSoldAsIs] = useState(false);
  const [homeWarrantyCompany, setHomeWarrantyCompany] = useState('');
  const [homeWarrantyCompanyOther, setHomeWarrantyCompanyOther] = useState('');
  const [applianceMake, setApplianceMake] = useState('');
  const [applianceModel, setApplianceModel] = useState('');
  const [applianceType, setApplianceType] = useState('');
  const [applianceSerial, setApplianceSerial] = useState('');
  const [repairEstimates, setRepairEstimates] = useState([]);
  const [initialRepairEstimates, setInitialRepairEstimates] = useState([]);
  const [inspectionReports, setInspectionReports] = useState([]);
  const [initialInspectionReports, setInitialInspectionReports] = useState([]);
  const [fromProfileMenu, setFromProfileMenu] = useState(false);
  const [lookedupProperties, setLookedupProperties] = useState([]);
  const [isEditState, setIsEditState] = useState(false);
  const [isNotesModalShown, setNotesModalShown] = useState(false);
  const [responses, setResponses] = useState([]);
  const [notesToShow, setNotesToShow] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const [rowData, setRowData] = useState([]);


  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  
  const loaded = React.useRef(false);

  function loadScript(src, position, id) {
    if (!position) {
      return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
  }

  const autocompleteService = { current: null };

  const applianceOptions = [
    { label: "Appliance (HVAC)", value: "Appliance" },
    { label: "Electrical", value: "Electrical" },
    { label: "Infestation", value: "Infestation" },
    { label: "Mold", value: "Mold" },
    { label: "Plumbing", value: "Plumbing" },
    { label: "Radon", value: "Radon" },
    { label: "Roof Leak", value: "Roof Leak" },
    { label: "Sewer Line", value: "Sewer Line" },
    { label: "Structural", value: "Structural" },
    { label: "Water Line", value: "Water Line" },
    { label: "Other", value: "Other" },
  ]

  const modalStyles = {
    overlay: {
      background: "rgba(0, 0, 0, 0.5)",
      overflowY: "scroll",
      zIindex: 1000
    },
    content: {
      top: '10%',
      left: '10%',
      right: 'auto',
      bottom: 'auto',

      //marginRight: '-50%',
      width: '80%',
      height: '90%'
      //transform: 'translate(-40%, -10%)',
    },
  }

  const smallModalStyles = {
    overlay: {
      background: "rgba(0, 0, 0, 0.5)",
      overflowY: "scroll",
      zIindex: 1000
    },
    content: {
      top: '30%',
      left: '30%',
      right: 'auto',
      bottom: 'auto',

      //marginRight: '-50%',
      width: '40%',
      height: '40%'
      //transform: 'translate(-40%, -10%)',
    },
  }

  const Header = () => {
    return (
      <header>
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="SubmitYourClaim" />
          </Link>
        </div>
        <nav>
          <ul>
            <li>
              <Link onClick={() => {
                setFromProfileMenu(true); setProfileModalIsOpen(true);
              }}>Profile</Link>
            </li>
            <li>
              <Link onClick={() => {
                openContactUsModal();
              }}>Contact</Link>
            </li>
            <li>
              <Link onClick={() => {
                props.signOut()
              }}>Logout</Link>
            </li>
          </ul>
        </nav>
      </header>
    );
  };


  const LinkCellRenderer = (props) => {
    if (["incomplete submission", "non-responsive"].includes(props?.value?.toLowerCase())) {
      return (<Link onClick={(e) => {   
        //alert(JSON.stringify(props.data.claim))
        editClaim(props.data.claimId, props.data.claim) 
      }}>{props.value}</Link>)
    } else {
      return (<div>{props.value}</div>);
    };
  };

  const NotesCellRenderer = (props) => {
      return (props.value && props.value !== 'null') ? (<div><Link onClick={(e) => {   
        //alert(JSON.stringify(props.data.claim))
        showNotesModal(props.data.claim) 
      }}>Claim Details</Link></div>) : (<div></div>)
  };

  // Column Definitions: Defines & controls grid columns.
  const [colDefs, setColDefs] = useState([
    { field: "claimIdViewable", headerName: "Claim ID" },
    {
      field: "status",
      cellRenderer: LinkCellRenderer
    },
    { field: "submissionDate" },
    { field: "notes", cellRenderer:NotesCellRenderer }
  ]);



  useEffect(() => {
    checkProfileIncomplete();
    setUserAttributeState();
    getClaims();

  }, []);

  const fetch = React.useMemo(
    () =>
      debounce(
        (
          request,
          callback,
        ) => {
          (autocompleteService.current)?.getPlacePredictions(
            request,
            callback,
          );
        },
        400,
      ),
    [],
  );


  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window).google) {
      autocompleteService.current = new (
        window
      ).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const showNotesModal = (claim) => {
    setNotesToShow(claim.responses?.map((claimReason) => (<label>{claimReason}</label>)));
    setNotesModalShown(true);
  }

  const checkProfileIncomplete = async () => {
    let attributes = await fetchUserAttributes();
    if (!attributes['custom:complete_profile'] || attributes['custom:complete_profile'] === 'false') {
      setProfileModalIsOpen(true);
    }
  }

  const getClaims = async () => {
    const response = await axios.get(`https://www.recallchek.com/recallchek/integration/GetClaims?integrationUserName=porchintegration&integrationPassword=TwdN9t7adjZ6Gk8q&submitterId=${props.user.userId}&json=true`)
    const claims = response?.data?.claims;
    const rowdata = claims?.map((claim) => {
      return {
        claimIdViewable: claim.claimNumber ? claim.claimNumber : "INITIATING",
        submissionDate: claim.createdDate,
        status: claim.status ? claim.status : "Awaiting Review",
        notes: claim.responses?.length > 0 ? claim.responses[0].substring(16) : "", //Assumes there is a date in front of each note
        claim: claim,
        claimId: claim.claimNumber
      }
    })
    setRowData(rowdata);
    console.log(response);
  }

  const editClaim = (claimId, claim) => {
    setEditClaimState(claimId, claim);
    setIsEditState(true);
    setClaimModalIsOpen(true);
  }

  const newClaim = () => {
    setClaimModalIsOpen(true);
    setIsEditState(false);
    setEditClaimState(null, null)
  }

  const openContactUsModal = () => {
    setFirstNameForContactUs(firstName);
    setLastNameForContactUs(lastName);
    setPhoneNumberForContactUs(phoneNumber);
    setEmailForContactUs(email);
    setContactUsModalIsOpen(true);
  }

  const setUserAttributeState = async () => {
    let attributes = await fetchUserAttributes();
    setFirstName(attributes.given_name)
    setLastName(attributes.family_name)
    setPhoneNumber(attributes.phone_number)
    setEmail(attributes.email)
    setStreet(attributes.address)
    setCity(attributes['custom:city'])
    setState(attributes['custom:state'])
    setZip(attributes['custom:zip'])
  }

  const submitContactUsAndClose = async () => {
  

    var getUrl = `https://vi6ztfzixn6ayn3gmfuvrzd2540twwvi.lambda-url.us-west-2.on.aws/?firstName=${firstName}&lastName=${lastName}&phoneNumber=${phoneNumber}&email=${email}&reason=${reasonForContactUs}`

    try {
      const response = await axios.get(getUrl);
      console.log(response);
    } catch (e) {
      console.log(e);
    }
    setContactUsModalIsOpen(false);
  }

  const saveProfileAndClose = async () => {

    if ((firstName === '') ||
      (lastName === '') ||
      (street === '') ||
      (city === '') ||
      (state === '') ||
      (zip === '') ||
      (phoneNumber === '')) {
      alert('All fields are required to continue updating your profile');
      return;
    }
    setLoading(true);
    const propertyList = [];
    try {
      propertyList = await lookupAddress(street);
      if (propertyList?.length === 0) {
        alert("We were unable to find a property with this address in our claims system. You may continue to enter a claim. However, claim processing will be delayed until we can match your claim to an existing address.")
      }
      const output = await  updateUserAttributes({userAttributes : {
        given_name: firstName,
        family_name:lastName,
        address: street,
        'custom:city': city,
        'custom:state': state, 
        'custom:zip':zip,
        'custom:complete_profile':firstName && lastName && street && city && state && zip ? 'true' : 'false'
        }}
      );
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
    closeModal();
  }

  const closeModal = () => {
    setProfileModalIsOpen(false);
  }

  const closeContactUsModal = () => {
    setContactUsModalIsOpen(false);
  }

  const setEditClaimState = (claimId, claim) => {
    
    setClaimDescription(claim ? claim.writtenNotice : "");
    setClaimNumber(claim ? claim.claimNumber : "");
    setClaimIssue(claim ? claim.claimIssue : "");
    setApplianceMake(claim ? claim.applianceMake : "");
    setApplianceType(claim ? claim.applianceType : "");
    setApplianceModel(claim ? claim.applianceModel : "");
    setApplianceSerial(claim ? claim.applianceSerial : "");
    setClosingDate(claim ?  Date.parse(claim.closedDate) : null);
    setInspectionDate(claim ? Date.parse(claim.inspectionDate) : null);
    setInspectionReports([]);
    setInitialInspectionReports(claim?.inspectionReports ? claim.inspectionReports : []);
    setRepairEstimates([]);
    setInitialRepairEstimates(claim?.repairEstimates ? claim.repairEstimates : []);
    setHomeWarrantyCompany(claim ? claim.homeWarrantyCompany : "");
    setHomeWarrantyCompanyOther(claim ? claim.homeWarrantyCompanyOther : "")
    setBankOwned(claim ? claim.bankOwned : false);
    setHomeOccupied(claim ? claim.occupied : false);
    setSoldAsIs(claim ? claim.soldAsIs : false);
    setResponses(claim ? claim.responses : []);
  }

  const submitClaimAndClose = async () => {
    if (claimDescription === '' ||
      claimIssue === '' ||
      (claimIssue === 'Appliance' && (applianceMake === '' || applianceModel === '' || applianceType === '' || applianceSerial === '')) ||
      closingDate === '' ||
      inspectionDate === '' ||
      oneYearWarranty === '' || 
      (oneYearWarranty === "Yes" && homeWarrantyCompany === '') ||
      bankOwned === '' ||
      soldAsIs === '') {
      alert('All fields are required to continue submitting your claim');
      return;
    }
    var getUrl = `https://www.recallchek.com/recallchek/integration/AddUpdateClaim?integrationUserName=porchintegration&integrationPassword=TwdN9t7adjZ6Gk8q&submitterId=${props.user.userId}` +
      `&firstName=${firstName}&lastName=${lastName}&phoneNumber=${phoneNumber}&email=${email}&street=${street}&city=${city}&state=${state}` +
      `&zipcode=${zip}&claimDescription=${claimDescription}&claimIssue=${claimIssue}&applianceType=${applianceType}&applianceMake=${applianceMake}&applianceModel=${applianceModel}&applianceSerial=${applianceSerial}` +
      `&inspectionDate=${inspectionDate}&closingDate=${closingDate}&homeWarrantyCompany=${homeWarrantyCompany}` +
      `&homeWarrantyCompanyOther=${homeWarrantyCompanyOther}&occupied=${homeOccupied}&bankOwned=${bankOwned}&soldAsIs=${soldAsIs}&json=true`
    let concatInspectionReports = initialInspectionReports?.concat(inspectionReports);
    let concatRepairEstimates = initialRepairEstimates?.concat(repairEstimates);
    concatInspectionReports?.map((inspectionReport) => getUrl += `&inspectionReports=${inspectionReport}`)
    concatRepairEstimates?.map((repairEstimate) => getUrl += `&repairEstimates=${repairEstimate}`)
    if (claimNumber && claimNumber.length > 0) getUrl +=`&claimNumber=${claimNumber}`
    setLoading(true);
    try {
      const response = await axios.get(getUrl);
    } catch (e) {
      setLoading(false);
    }
    setLoading(false);
    setClaimModalIsOpen(false);
    getClaims();
  }

  const closeClaimForm = () => {
    setClaimModalIsOpen(false);
  }

  const processInspectionReport = async ({ file }) => {
    return processFile('Inspection Report', file)
  }

  const processRepairEstimate = async ({ file }) => {
    return processFile('Repair Estimate', file)
  }

  const processFile = async (type, file) => {
    const fileExtension = file.name.split('.').pop();

    return file
      .arrayBuffer()
      .then((filebuffer) => window.crypto.subtle.digest('SHA-1', filebuffer))
      .then((hashBuffer) => {
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray
          .map((a) => a.toString(16).padStart(2, '0'))
          .join('');
        return { file, key: `${type}_${hashHex}.${fileExtension}` };
      });
  };

  async function lookupAddress() {
    var getUrl = `https://www.recallchek.com/recallchek/integration/LookupAddress?integrationUserName=porchintegration&integrationPassword=TwdN9t7adjZ6Gk8q&submitterId=${props.user.userId}&` +
      `address=${street}&json=true`
    const response = await axios.get(getUrl);
    return response?.data?.properties ?? [];

  }

  function updateHomeWarrantyCompany(e) {
    if (e === 'other') {
      alert('Your Home Inspection Warranty is not combinable with ay other valid coverages. Since you have a 1-year home warranty with another company you need to file your claim with them BEFORE this Home Inspection Warranty. All coverages, including your 1 Year home warranty, come before this warranty on your full home inspection.');
    }
    setHomeWarrantyCompany(e);
  }

  return (

      <View style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", marginTop: "1remgit "}}>
        <View
          as="div"
          ariaLabel="View example"
          backgroundColor="var(--amplify-colors-white)"
          borderRadius="6px"
          border="1px solid var(--amplify-colors-black)"
          boxShadow="3px 3px 5px 6px var(--amplify-colors-neutral-60)"
          color="var(--amplify-colors-blue-30)"
          height="60%"
          maxWidth="80%"
          width="70%"
          overflowY="hidden"
          backgroundImage= {`url(https://submityourclaim16ff83b99b1b4eeeb0d41905813e3f9d233037-staging.s3.us-west-2.amazonaws.com/public/background.png)`}

        >
          <Header />

          <Modal isOpen={profileModalIsOpen}
            style={modalStyles}
            onRequestClose={closeModal}>
            <div className={`${baseClassName}`}>
              {!fromProfileMenu && (<header className={`${baseClassName}__header`}>
                <h1 className={`${baseClassName}__title`}>Get started today</h1>
                <h2 className={`${baseClassName}__message`}>Welcome to SubmitYourClaim.net.  Tell us a little about yourself. </h2>
              </header>)}

              <div>
                
                <div className="claim-form">
                <text>Policy Holder Info:</text><br/>
                <text>This information will be used for all claims submitted. Fill this form with complete, valid information for fastest turnaround.</text><br/>

                  <TextField
                    id="first-name"
                    label="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    margin="normal"
                  />
                  <TextField
                    id="last-name"
                    label="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    margin="normal"
                  />
                  <TextField
                    id="phone-number"
                    label="Phone Number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    margin="normal"
                  />
                  { false && (<AutoComplete
                    autoComplete="off"
                    sx={{ width: 300 }}
                    getOptionLabel={(option) =>
                      typeof option === 'string' ? option : option.description
                    }
                    filterOptions={(x) => x}
                    options={options}
                    includeInputInList
                    filterSelectedOptions
                    value={value}
                    noOptionsText="No locations"
                    onChange={(event, newValue) => {
                      setOptions(newValue ? [newValue, ...options] : options);
                      setValue(newValue);
                    }}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} 
                      label="Where is your home located?" fullWidth />
                    )}
                    renderOption={(props, option) => {
                      const matches =
                        option.structured_formatting.main_text_matched_substrings || [];

                      const parts = parse(
                        option.structured_formatting.main_text,
                        matches.map((match) => [match.offset, match.offset + match.length]),
                      );

                      return (
                        <li {...props}>
                          <Grid container alignItems="center">
                            <Grid item sx={{ display: 'flex', width: 44 }}>
                              {//<LocationOnIcon sx={{ color: 'text.secondary' }} />
                              }
                            </Grid>
                            <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                              {parts.map((part, index) => (
                                <Box
                                  key={index}
                                  component="span"
                                  sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                                >
                                  {part.text}
                                </Box>
                              ))}
                              <Typography variant="body2" color="text.secondary">
                                {option.structured_formatting.secondary_text}
                              </Typography>
                            </Grid>
                          </Grid>
                        </li>
                      );
                    }}
                  />)}
                  <label style={{color:"red"}}>Address exactly as it appears on your inspection report.</label>
                  
                  <TextField
                    id="street"
                    label="Street"
                    value={street}
                    
                    onChange={(e) => setStreet(e.target.value)}
                    margin="normal"
                  />
                  <TextField
                    id="city"
                    label="City"
                    value={city}
                    
                    onChange={(e) => setCity(e.target.value)}
                    margin="normal"
                  />
                  <TextField
                    id="state"
                    label="State"
                    
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    margin="normal"
                  />
                  <TextField
                    id="zip"
                    label="Zip"
                    
                    value={zip}
                    onChange={(e) => setZip(e.target.value)}
                    margin="normal"
                  />
                </div>
              </div>

              <button disabled={isLoading} onClick={() => { saveProfileAndClose(); }}>Save</button>
              <button disabled={isLoading} onClick={() => { setProfileModalIsOpen(false);}}>Cancel</button>
              {isLoading && (
                <CircularProgress size={25} />
              )}

            </div>
          </Modal>
          <Modal isOpen={contactUsModalIsOpen}
            style={modalStyles}
            onRequestClose={closeContactUsModal}>
            <div className={`${baseClassName}`}>
              <h1>Talk with an advisor:</h1>
              <h2>Please fill out the form below and an advisor will contact you shortly. </h2>

              <div>
                <div className="claim-form">
                  <TextField
                    id="first-name"
                    label="First Name"
                    value={firstNameForContactUs}
                    onChange={(e) => setFirstNameForContactUs(e.target.value)}
                    margin="normal"
                  />
                  <TextField
                    id="last-name"
                    label="Last Name"
                    value={lastNameForContactUs}
                    onChange={(e) => setLastNameForContactUs(e.target.value)}
                    margin="normal"
                  />
                  <TextField
                    id="phone-number"
                    label="Phone Number"
                    value={phoneNumberForContactUs}
                    onChange={(e) => setPhoneNumberForContactUs(e.target.value)}
                    margin="normal"
                  />
                  <TextField
                    id="email"
                    label="Email"
                    value={emailForContactUs}
                    onChange={(e) => setEmailForContactUs(e.target.value)}
                    margin="normal"
                  />
                  <TextField
                    id="reason"
                    label="Reason For Contact"
                    value={reasonForContactUs}
                    onChange={(e) => setReasonForContactUs(e.target.value)}
                    margin="normal"
                  />
                </div>
              </div>

              <button onClick={() => { submitContactUsAndClose(); }}>Submit</button>

            </div>
          </Modal>
          <Modal isOpen={isNotesModalShown}
            style={smallModalStyles}
            onRequestClose={() => setNotesModalShown(false)}>
            <div className={`${baseClassName}`}>
              {notesToShow}
            </div>
          </Modal>
          <Modal isOpen={claimModalIsOpen}
            style={modalStyles}
            >
            
            <div class="ui-g ui-fluid">
              <Box
                component="form"
                sx={{
                  '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
              >
                <br />
                {!isEditState && (<Container style={{ width: "100%" }}>
                  <View backgroundImage= {`url(https://submityourclaim16ff83b99b1b4eeeb0d41905813e3f9d233037-staging.s3.us-west-2.amazonaws.com/public/background.png)`}>
                  <Row>
                    <Col>
                      <label>What best categorizes the issue you are having?</label>
                    </Col>
                    <Col>
                      <TextField
                        select
                        onChange={(e) => setClaimIssue(e.target.value)}
                        helperText=" "
                        value={claimIssue}>
                        {applianceOptions.map((option) => (<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>))}
                      </TextField>
                    </Col>
                  </Row>
                  {claimIssue?.toLowerCase() === 'appliance' && (
                    <Row>
                      <TextField
                        id="appliance-type"
                        label="Type"
                        value={applianceType}
                        onChange={(e) => setApplianceType(e.target.value)}
                        margin="normal"
                      />
                      <br />
                      <TextField
                        id="appliance-make"
                        label="Make"
                        value={applianceMake}
                        onChange={(e) => setApplianceMake(e.target.value)}
                        margin="normal"
                      />
                      <br />
                      <TextField
                        id="applaince-model"
                        label="Model"
                        value={applianceModel}
                        onChange={(e) => setApplianceModel(e.target.value)}
                        margin="normal"
                      />
                      <br />
                      <TextField
                        id="appliance-serial"
                        label="Serial"
                        value={applianceSerial}
                        onChange={(e) => setApplianceSerial(e.target.value)}
                        margin="normal"
                      />
                      <br />
                    </Row>
                  )}
                  {claimIssue?.toLowerCase() === 'other' && (
                    <Row>
                      <Col>
                        <label>How would you categorize the issue you are having?</label>
                      </Col>
                      <Col>



                        <TextField
                          id="claimIssueOther"
                          required="true"
                          value={claimIssueOther}
                          onChange={(e) => { setClaimIssueOther(e.target.value) }}
                          helperText=" " />

                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col>
                      <label>Is there a 1 Year Warranty on the Home?</label>
                    </Col>
                    <Col>
                      <TextField onChange={(e) => setOneYearWarranty(e.target.value)} select id="hasOneYearWarranty" helperText=" " value={oneYearWarranty}>
                        <MenuItem key="yes" value="true">Yes</MenuItem>
                        <MenuItem key="no" value="false">No</MenuItem>
                      </TextField>
                    </Col>
                  </Row>
                  {oneYearWarranty === "true" && (
                    <Row>
                      <Col>
                        <label>Which Home Warranty Company are you using?</label>
                      </Col>
                      <Col>
                        <TextField
                          select
                          id="HomeWarrantyCompany"
                          helperText=" "
                          value={homeWarrantyCompany}
                          onChange={(e) => { updateHomeWarrantyCompany(e.target.value)}}
                        >
                          <MenuItem key="RWS" value="RWS">Residential Warranty Services</MenuItem>
                          <MenuItem key="RWHP" value="RWHP">American Home Protect</MenuItem>
                          <MenuItem key="other" value="other">Other</MenuItem>
                        </TextField>
                      </Col>
                    </Row>
                  )}
                  {homeWarrantyCompany?.toLowerCase() === 'other' && (
                    <Row>
                      <Col>
                        <label>Enter Home Warranty Company Name</label>
                      </Col>
                      <Col>
                        <TextField
                          id="homeWarrantyCompanyOther"
                          required="true"
                          value={homeWarrantyCompanyOther}
                          onChange={(e) => { setHomeWarrantyCompanyOther(e.target.value) }}
                          helperText=" " />
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col>
                      <label>Is the home occupied?</label>
                    </Col>
                    <Col>
                      <TextField
                        select
                        id="homeOccupied"
                        helperText=" "
                        value={homeOccupied}
                        onChange={(e) => { setHomeOccupied(e.target.value) }}
                      >
                        <MenuItem key="yes" value="true">Yes</MenuItem>
                        <MenuItem key="no" value="false">No</MenuItem>
                      </TextField>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label>Is the home bank owned?</label>
                    </Col>
                    <Col>
                      <TextField
                        select
                        id="bankOwned"
                        helperText=" "
                        value={bankOwned}
                        onChange={(e) => { setBankOwned(e.target.value) }}
                      >
                        <MenuItem key="yes" value="true">Yes</MenuItem>
                        <MenuItem key="no" value="false">No</MenuItem>
                      </TextField>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label>Was the Home Sold As Is?</label>
                    </Col>
                    <Col>
                      <TextField
                        select
                        id="soldAsIs"
                        helperText=" "
                        value={soldAsIs}
                        onChange={(e) => { setSoldAsIs(e.target.value) }}
                      >
                        <MenuItem key="yes" value="true">Yes</MenuItem>
                        <MenuItem key="no" value="false">No</MenuItem>
                      </TextField>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label>What's the date of your home inspection?</label>
                    </Col>
                    <Col>
                      <DatePicker onChange={(e) => setInspectionDate(e ? e.toLocaleDateString() : '')} id="inspectionDate" value={inspectionDate} label="" />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label>When did you close on the Home?</label>
                    </Col>
                    <Col>
                      <DatePicker onChange={(e) => setClosingDate(e ? e.toLocaleDateString() : '')} id="closingDate" value={closingDate} label="" />
                    </Col>
                  </Row>
                  </View>
                </Container>
                )}
                {isEditState && (
                  <Container>
                    {responses.map((response) => (
                    <Row>
                      <Col>
                        <label>{response}</label><br/>
                      </Col>
                    </Row>))}
                  </Container>
                )}
                <br />
                <br />
                <br />
              
                <Container>
                  <Row>
                    <Col>
                      <h3>Your estimate, from a licensed contractor, must include:</h3>
                    </Col>
                  </Row>
                  
                  <Row>
                    <Col style={{ fontSize: 12, margin: "2px", border: "1px solid black", width:"20%" }}>
                    <div style={{height:100}}>
                    <div style={{height: 50, padding: "3px"}}>
                    <img src={roofprotection} width="100%" /></div><span><br/><b>ROOF LEAK</b></span></div>
                    <li>Number of Leaks</li>
                      <li>Location of Leaks</li>
                      <li>Square Footage</li>
                      <li>Cause of each leak (e.g. wear & tear, age)</li>
                      <li>Price breakdown for materials separated by leak</li>
                      <li>Price for labor separated by leak(s)</li>
                    </Col>
                    <Col style={{ fontSize: 12, margin: "2px", border: "1px solid black", width:"20%" }}>
                    <div style={{height:100}}>
                    <div style={{height: 50, padding: "3px"}}>
                      <img src={warranty} width="100%" /></div>
                      <span><br/><b>HVAC/ELECTRICAL<br />APPLIANCE/PLUMBING</b></span></div>
                      <li>Diagnosis/Initial Failure (i.e. a brief description of the failure)</li>
                      <li>Specific Cause of Failure (e.g. wear & tear, age or something else in their professional opinion)</li>
                      <li>Itemized Cost of Parts</li>
                      <li>Cost/ Hours of Labor</li>
                      <li>Brand, Model & Serial Numbers of failed Unit</li>
                    </Col>
                    <Col style={{ fontSize: 12, margin: "2px", border: "1px solid black", width:"20%" }}>
                    <div style={{height:100}}>
                    <div style={{height: 50, padding: "3px"}}>
                    <img src={nxtstructural} width="100%" /></div><span><br/><b>STRUCTURAL</b></span></div>
                      <li>Diagnosis/Initial Failure (i.e. a brief description of the failure)</li>
                      <li>Specific Cause of Failure (e.g. wear & tear, age or something else in their professional opinion)</li>
                      <li>Description of what's been done to address the failure</li>
                      <li>Itemized Cost of Parts</li>
                      <li>Cost/ Hours of Labor</li>
                    </Col>
                    <Col style={{ fontSize: 12, margin: "2px", border: "1px solid black", width:"20%"}}>
                    <div style={{height:100}}>
                    <div style={{height: 50, padding: "3px"}}>
                    <img src={sewergard} width="100%" /></div><span><br/><b>SEWERGARD</b></span></div>
                      <li>Diagnosis (i.e. brief description of failure - is it a break/belly/clog?)</li>

                      <li>Location of break (i.e. inside or outside foundation)</li>

                      <li>Length of broken portion of underground pipe</li>

                      <li>Cause of break (e.g. wear & tear, age, or something else in their professional opinion)</li>

                    </Col>
                    <Col style={{ fontSize: 12, margin: "2px", border: "1px solid black" }}>
                    <div style={{height:100}}>
                    <div style={{height: 50, padding: "3px"}}>
                    <img src={moldsafe} width="100%" />
                    </div><span><br/><b>MOLDSAFE</b></span></div>
                      <li>Square footage of area treated</li>

                      <li>Location of visible mold (must be visible)</li>

                      <li>What is being removed or cleaned</li>

                      <li>Cause of mold (in their professional opinion)</li>

                      <li>Name of chemical
                      </li>
                      <li>Cost for chemical per unit</li>

                      <li>Cost of labor to apply chemical</li>
                    </Col>
                  </Row>

                </Container>
                <Container>
                <label>Upload your repair estimate.</label>
                <StorageManager
                  accessLevel='public'
                  acceptedFileTypes={['image/*', ['.pdf'], ['.doc']]}
                  maxFileCount={5}
                  processFile={processRepairEstimate}
                  onUploadSuccess={(upload) => {
                    repairEstimates.push(upload.key);
                    setRepairEstimates(repairEstimates);
                  }}
                />
                {//initialRepairEstimates && initialRepairEstimates.map((report) => {
                  //<div><label>{report}</label><br/></div>
                //} 
                //)
                }
                <label>Upload your inspection report.</label>
                <StorageManager
                  accessLevel='public'
                  acceptedFileTypes={['image/*', ['.pdf'], ['.doc']]}
                  maxFileCount={5}
                  processFile={processInspectionReport}
                  onUploadSuccess={(upload) => {
                    inspectionReports.push(upload.key);
                    setInspectionReports(inspectionReports);

                  }}
                />
                {//initialInspectionReports && initialInspectionReports.map((report) => 
                  //<div><label>{report}</label><br/></div>
                //
                //)
                }
                <div>
                <label>Provide a brief description of the claim you are filing:</label>
                <br></br>
                <input type="hidden" value={claimNumber} />
                <TextField required="true"
                  minRows="5"
                  multiline
                  fullWidth
                  style={{ width: "95%" }}
                  onChange={(e) => setClaimDescription(e.target.value)}
                  value={claimDescription}
                  helperText=" "
                  id="claimDescription" />
                </div>
                <Button onClick={() => closeClaimForm()}>Cancel</Button>
                
                <Button disabled={isLoading} onClick={() => submitClaimAndClose()}>Submit Claim</Button>
                {isLoading && (
                <CircularProgress size={25} />
                )}
                </Container>
                
              </Box>
            </div>
            
            
            
          </Modal>
          <h2 style={{paddingLeft: "1rem"}}>Welcome back, {firstName}.  <br /></h2>
          <h style={{paddingLeft: "1rem"}}>Below, you can enter a claim by clicking the "New Claim" button.</h><br/>
          <h style={{paddingLeft: "1rem"}}>You will also find a list of past claims submitted.  </h><br/>
          <h style={{paddingLeft: "1rem"}}> If any claim requires more information, you can click the status link to see the claim and make updates. </h><br />
          <div style={{ width: "100%", height: 400, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
            <div className="ag-theme-quartz" style={{ height: 300, width: "70%" }}>
              <AgGridReact rowData={rowData} columnDefs={colDefs} overlayNoRowsTemplate='&nbsp' />
            </div>
            <div style={{ display: "flex" }}>
              <Button style={{ marginLeft: "auto" }} onClick={() => { newClaim() }}>New Claim</Button>
            </div>
          </div>
        </View>
      </View>


  );
}

const Header = () => <p>I am the header component</p>;

export default App;
