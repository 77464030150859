import CircularProgress from "@mui/material/CircularProgress";
import logo from './logo_color.png';
import roofprotection from './images/roofprotection.png'
import moldsafe from './images/moldsafe.png'
import sewergard from './images/sewergard.png'
import nxtstructural from './images/nxtstructural.png'
import warranty from './images/warranty.jpg'
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import "@aws-amplify/ui-react/styles.css";
import Modal from 'react-modal';
import {
  Button,
  View,
} from "@aws-amplify/ui-react";
import {
  fetchUserAttributes
} from 'aws-amplify/auth';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import 'react-calendar/dist/Calendar.css';
import 'react-date-picker/dist/DatePicker.css';
import { AgGridReact } from 'ag-grid-react'; // React Grid Logic
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "./AdminApp.css"
import { StorageManager } from '@aws-amplify/ui-react-storage'
import axios from 'axios'
import { Container, Row, Col } from 'react-grid';

const baseClassName = 'main';

function AdminApp(props) {

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [claimModalIsOpen, setClaimModalIsOpen] = useState(false);
  const [claimNumber, setClaimNumber] = useState('');
  const [claimDescription, setClaimDescription] = useState('');
  const [claimIssue, setClaimIssue] = useState('');
  const [claimIssueOther, setClaimIssueOther] = useState('');
  const [inspectionDate, setInspectionDate] = useState('');
  const [closingDate, setClosingDate] = useState('');
  const [oneYearWarranty, setOneYearWarranty] = useState(false);
  const [homeOccupied, setHomeOccupied] = useState(false);
  const [bankOwned, setBankOwned] = useState(false);
  const [soldAsIs, setSoldAsIs] = useState(false);
  const [homeWarrantyCompany, setHomeWarrantyCompany] = useState('');
  const [homeWarrantyCompanyOther, setHomeWarrantyCompanyOther] = useState('');
  const [applianceMake, setApplianceMake] = useState('');
  const [applianceModel, setApplianceModel] = useState('');
  const [applianceType, setApplianceType] = useState('');
  const [applianceSerial, setApplianceSerial] = useState('');
  const [repairEstimates, setRepairEstimates] = useState([]);
  const [initialRepairEstimates, setInitialRepairEstimates] = useState([]);
  const [inspectionReports, setInspectionReports] = useState([]);
  const [initialInspectionReports, setInitialInspectionReports] = useState([]);
  const [rejectReason, setRejectReason] = useState('');
  const [responses, setResponses] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [unmatchedRowData, setUnmatchedRowData] = useState([]);
  const [matchedRowData, setMatchedRowData] = useState([]);

  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  
  const loaded = React.useRef(false);

  const applianceOptions = [
    { label: "Appliance (HVAC)", value: "Appliance" },
    { label: "Electrical", value: "Electrical" },
    { label: "Infestation", value: "Infestation" },
    { label: "Mold", value: "Mold" },
    { label: "Plumbing", value: "Plumbing" },
    { label: "Radon", value: "Radon" },
    { label: "Roof Leak", value: "Roof Leak" },
    { label: "Sewer Line", value: "Sewer Line" },
    { label: "Structural", value: "Structural" },
    { label: "Water Line", value: "Water Line" },
    { label: "Other", value: "Other" },
  ]

  const modalStyles = {
    overlay: {
      background: "rgba(0, 0, 0, 0.5)",
      overflowY: "scroll",
      zIindex: 1000
    },
    content: {
      top: '10%',
      left: '10%',
      right: 'auto',
      bottom: 'auto',

      //marginRight: '-50%',
      width: '80%',
      height: '90%'
      //transform: 'translate(-40%, -10%)',
    },
  }

  const smallModalStyles = {
    overlay: {
      background: "rgba(0, 0, 0, 0.5)",
      overflowY: "scroll",
      zIindex: 1000
    },
    content: {
      top: '30%',
      left: '30%',
      right: 'auto',
      bottom: 'auto',

      //marginRight: '-50%',
      width: '40%',
      height: '40%'
      //transform: 'translate(-40%, -10%)',
    },
  }
  const LinkCellRenderer = (props) => {
    return (<Link onClick={(e) => {   
      editClaim(props.data.claim) 
    }}>{props.value}</Link>)
  };

  const [colDefs, setColDefs] = useState([
    { field: "claimIdViewable", headerName: "Claim ID", filter: true, floatingFilter: true},
    {
      field: "status",
      cellRenderer: LinkCellRenderer
    },
    { field: "submissionDate" },
    
  ]);

  useEffect(() => {
    getUnmatchedSubmittedClaims();
    getMatchedSubmittedClaims();
  }, []);

  const getUnmatchedSubmittedClaims = async () => {
    const response = await axios.get(`https://www.recallchek.com/recallchek/integration/GetUnmatchedClaims?integrationUserName=porchintegration&integrationPassword=TwdN9t7adjZ6Gk8q&adminId=${props.user.userId}&json=true`)
    const claims = response?.data?.claims;
    const rowdata = claims?.map((claim) => {
      return {
        claimIdViewable: claim.claimNumber ? claim.claimNumber : "INITIATING",
        submissionDate: claim.createdDate,
        status: claim.status ? claim.status : "Awaiting Review",
        notes: claim.responses?.length > 0 ? claim.responses[0].substring(16) : "", //Assumes there is a date in front of each note
        claim: claim,
        claimId: claim.claimNumber
      }
    })
    setUnmatchedRowData(rowdata);
    console.log(response);
  }

  const getMatchedSubmittedClaims = async () => {
    const response = await axios.get(`https://www.recallchek.com/recallchek/integration/GetMatchedSubmittedClaims?integrationUserName=porchintegration&integrationPassword=TwdN9t7adjZ6Gk8q&adminId=${props.user.userId}&json=true`)
    const claims = response?.data?.claims?.sort((a, b) => +a.claimNumber - +b.claimNumber);
    const rowdata = claims?.map((claim) => {
      return {
        claimIdViewable: claim.claimNumber ? claim.claimNumber : "INITIATING",
        submissionDate: claim.createdDate,
        status: claim.status ? claim.status : "Awaiting Review",
        notes: claim.responses?.length > 0 ? claim.responses[0].substring(16) : "", //Assumes there is a date in front of each note
        claim: claim,
        claimId: claim.claimNumber
      }
    })
    setMatchedRowData(rowdata);
    console.log(response);
  }

  const editClaim = (claim) => {
    setEditClaimState(claim);
    setClaimModalIsOpen(true);
  }

  const setUserAttributeState = async () => {
    let attributes = await fetchUserAttributes();
    setFirstName(attributes.given_name)
    setLastName(attributes.family_name)
    setPhoneNumber(attributes.phone_number)
    setEmail(attributes.email)
    setStreet(attributes.address)
    setCity(attributes['custom:city'])
    setState(attributes['custom:state'])
    setZip(attributes['custom:zip'])
  }

  const setEditClaimState = (claim) => {
    setFirstName(claim.firstName)
    setLastName(claim.lastName)
    setPhoneNumber(claim.phoneNumber)
    setEmail(claim.email)
    setStreet(claim.street);
    setCity(claim.city);
    setState(claim.state);
    setZip(claim.zipCode);
    setClaimDescription(claim ? claim.writtenNotice : "");
    setClaimNumber(claim ? claim.claimNumber : "");
    setClaimIssue(claim ? claim.claimIssue : "");
    setApplianceMake(claim ? claim.applianceMake : "");
    setApplianceType(claim ? claim.applianceType : "");
    setApplianceModel(claim ? claim.applianceModel : "");
    setApplianceSerial(claim ? claim.applianceSerial : "");
    const closeDate = new Date(claim.closedDate);
    setClosingDate((closeDate.getMonth()+1) + "/" + closeDate.getDate() + "/" + closeDate.getFullYear());
    const inspectionDate = new Date(claim.inspectionDate);
    setInspectionDate((inspectionDate.getMonth()+1) + "/" + inspectionDate.getDate() + "/" + inspectionDate.getFullYear());
    setInspectionReports([]);
    setInitialInspectionReports(claim?.inspectionReports ? claim.inspectionReports : []);
    setRepairEstimates([]);
    setInitialRepairEstimates(claim?.repairEstimates ? claim.repairEstimates : []);
    setHomeWarrantyCompany(claim ? claim.homeWarrantyCompany : "");
    setHomeWarrantyCompanyOther(claim ? claim.homeWarrantyCompanyOther : "")
    setBankOwned(claim ? claim.bankOwned : false);
    setHomeOccupied(claim ? claim.occupied : false);
    setSoldAsIs(claim ? claim.soldAsIs : false);
    setResponses(claim ? claim.responses : []);
    setRejectReason('');
  }

  const rejectClaimSubmittalAndClose = async () => {
    var getUrl = `https://www.recallchek.com/recallchek/integration/RejectSubmittedClaim?integrationUserName=porchintegration&integrationPassword=TwdN9t7adjZ6Gk8q&adminId=${props.user.userId}` +
                `&claimNumber=${claimNumber}&reason=${rejectReason}`
    try {
      const response = await axios.get(getUrl);
    } catch (e) {
      console.log(e);
    }
    setClaimModalIsOpen(false);
    getUnmatchedSubmittedClaims();
  }

  const submitClaimAndClose = async () => {
    if (claimDescription === '' ||
      claimIssue === '' ||
      (claimIssue === 'Appliance' && (applianceMake === '' || applianceModel === '' || applianceType === '' || applianceSerial === '')) ||
      closingDate === '' ||
      inspectionDate === '' ||
      oneYearWarranty === '' || 
      (oneYearWarranty === "Yes" && homeWarrantyCompany === '') ||
      bankOwned === '' ||
      soldAsIs === '') {
      alert('All fields are required to continue submitting your claim');
      return;
    }
    var getUrl = `https://www.recallchek.com/recallchek/integration/AddUpdateClaim?integrationUserName=porchintegration&integrationPassword=TwdN9t7adjZ6Gk8q&submitterId=${props.user.userId}` +
      `&firstName=${firstName}&lastName=${lastName}&phoneNumber=${phoneNumber}&email=${email}&street=${street}&city=${city}&state=${state}` +
      `&zipcode=${zip}&claimDescription=${claimDescription}&claimIssue=${claimIssue}&applianceType=${applianceType}&applianceMake=${applianceMake}&applianceModel=${applianceModel}&applianceSerial=${applianceSerial}` +
      `&inspectionDate=${inspectionDate}&closingDate=${closingDate}&homeWarrantyCompany=${homeWarrantyCompany}` +
      `&homeWarrantyCompanyOther=${homeWarrantyCompanyOther}&occupied=${homeOccupied}&bankOwned=${bankOwned}&soldAsIs=${soldAsIs}&json=true`
    let concatInspectionReports = initialInspectionReports?.concat(inspectionReports);
    let concatRepairEstimates = initialRepairEstimates?.concat(repairEstimates);
    concatInspectionReports?.map((inspectionReport) => getUrl += `&inspectionReports=${inspectionReport}`)
    concatRepairEstimates?.map((repairEstimate) => getUrl += `&repairEstimates=${repairEstimate}`)
    if (claimNumber && claimNumber.length > 0) getUrl +=`&claimNumber=${claimNumber}`

    const response = await axios.get(getUrl);
    setClaimModalIsOpen(false);
    getUnmatchedSubmittedClaims();
    getMatchedSubmittedClaims();
  }

  const closeClaimForm = () => {
    setClaimModalIsOpen(false);
  }

  const processInspectionReport = async ({ file }) => {
    return processFile('Inspection Report', file)
  }

  const processRepairEstimate = async ({ file }) => {
    return processFile('Repair Estimate', file)
  }

  const processFile = async (type, file) => {
    const fileExtension = file.name.split('.').pop();

    return file
      .arrayBuffer()
      .then((filebuffer) => window.crypto.subtle.digest('SHA-1', filebuffer))
      .then((hashBuffer) => {
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray
          .map((a) => a.toString(16).padStart(2, '0'))
          .join('');
        return { file, key: `${type}_${hashHex}.${fileExtension}` };
      });
  };

  async function lookupAddress() {
    var getUrl = `https://www.recallchek.com/recallchek/integration/LookupAddress?integrationUserName=porchintegration&integrationPassword=TwdN9t7adjZ6Gk8q&submitterId=${props.user.userId}&` +
      `address=${street}&json=true`
    const response = await axios.get(getUrl);
    return response?.data?.properties ?? [];

  }

  return (

      <View style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", marginTop: "1remgit "}}>
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="SubmitYourClaim" />
          </Link>
        </div>

        <View
          as="div"
          ariaLabel="View example"
          backgroundColor="var(--amplify-colors-white)"
          borderRadius="6px"
          border="1px solid var(--amplify-colors-black)"
          boxShadow="3px 3px 5px 6px var(--amplify-colors-neutral-60)"
          color="var(--amplify-colors-blue-30)"
          height="60%"
          maxWidth="80%"
          width="70%"
          overflowY="hidden"
         

        >
          
          
          
          <Modal isOpen={claimModalIsOpen}
            style={modalStyles}
            >
            
            <div class="ui-g ui-fluid">
              <Box
                component="form"
                sx={{
                  '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
              >
                <br />
                {(<Container style={{ width: "100%" }}>
                  <View>
                  <Row>
                  <label>{firstName} {lastName}</label>
                  </Row>
                  <Row>
                  <label>{phoneNumber}</label>
                  </Row>
                  <Row>
                  <label>{email}</label>
                  </Row>
                  
                  <label style={{color:"red"}}>Address exactly as it appears on your inspection report.</label>

                  <Row>
                    <Col>
                  <TextField
                    id="street"
                    label="Street"
                    value={street}
                    
                    onChange={(e) => setStreet(e.target.value)}
                    margin="normal"
                  />
                  </Col></Row>
                  <Row>
                    <Col>
                  <TextField
                    id="city"
                    label="City"
                    value={city}
                    
                    onChange={(e) => setCity(e.target.value)}
                    margin="normal"
                  />
                  </Col></Row>
                  <Row>
                    <Col>
                  <TextField
                    id="state"
                    label="State"
                    
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    margin="normal"
                  />
                  </Col></Row>
                  <Row>
                    <Col>
                  <TextField
                    id="zip"
                    label="Zip"
                    
                    value={zip}
                    onChange={(e) => setZip(e.target.value)}
                    margin="normal"
                  />
                  </Col></Row>
                  <Row>
                    <Col>
                      <label>What best categorizes the issue you are having?</label>
                    </Col>
                    <Col>
                      <label>
                        {claimIssue}
                      </label>
                  <label>{applianceType}</label>
                    </Col>
                  </Row>
                  {claimIssue?.toLowerCase() === 'appliance' && (
                    <Row>
                      <br />
                      <label>{applianceMake}</label>
                      <br />
                      <label>{applianceModel}</label>
                      <br />
                      <label>{applianceSerial}</label>
                      <br />
                    </Row>
                  )}
                  {claimIssue?.toLowerCase() === 'other' && (
                    <Row>
                      <Col>
                        <label>How would you categorize the issue you are having?</label>
                      </Col>
                      <Col>
                      <label>{claimIssueOther}</label>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col>
                      <label>Is there a 1 Year Warranty on the Home?</label>
                    </Col>
                    <Col>
                    <label>{oneYearWarranty}</label>
                    </Col>
                  </Row>
                  {oneYearWarranty === "true" && (
                    <Row>
                      <Col>
                        <label>Which Home Warranty Company are you using?</label>
                      </Col>
                      <Col>
                        <label>{homeWarrantyCompany}</label>
                      </Col>
                    </Row>
                  )}
                  {homeWarrantyCompany?.toLowerCase() === 'other' && (
                    <Row>
                      <Col>
                        <label>Enter Home Warranty Company Name</label>
                      </Col>
                      <Col>
                      <label>{homeWarrantyCompanyOther}</label>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col>
                      <label>Is the home occupied?</label>
                    </Col>
                    <Col>
                    <label>{homeOccupied}</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label>Is the home bank owned?</label>
                    </Col>
                    <Col>
                    <label>{bankOwned}</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label>Was the Home Sold As Is?</label>
                    </Col>
                    <Col>
                    <label>{soldAsIs}</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label>What's the date of your home inspection?</label>
                    </Col>
                    <Col>
                    <TextField
                        id="inspectionDate"
                        label="Inspection Date (MM/DD/YYYY)"
                        
                        value={inspectionDate}
                        onChange={(e) => setInspectionDate(e.target.value)}
                        margin="normal"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label>When did you close on the Home?</label>
                    </Col>
                    <Col>
                      <TextField
                        id="closingDate"
                        label="Closing Date (MM/DD/YYYY)"
                        
                        value={closingDate}
                        onChange={(e) => setClosingDate(e.target.value)}
                        margin="normal"
                      />
                      
                    </Col>
                  </Row>
                  </View>
                </Container>
                )}
                <br />
                <br />
                <br />
              
                <Container>
                
                <label>Upload your repair estimate.</label>
                <StorageManager
                  accessLevel='public'
                  acceptedFileTypes={['image/*', ['.pdf'], ['.doc']]}
                  maxFileCount={5}
                  processFile={processRepairEstimate}
                  onUploadSuccess={(upload) => {
                    repairEstimates.push(upload.key);
                    setRepairEstimates(repairEstimates);
                  }}
                />
                
                {initialRepairEstimates && initialRepairEstimates.map((report) => {
                  <div><label>{report}</label><br/></div>
                } 
                )
                }
                <label>Upload your inspection report.</label>
                <StorageManager
                  accessLevel='public'
                  acceptedFileTypes={['image/*', ['.pdf'], ['.doc']]}
                  maxFileCount={5}
                  processFile={processInspectionReport}
                  onUploadSuccess={(upload) => {
                    inspectionReports.push(upload.key);
                    setInspectionReports(inspectionReports);

                  }}
                />
                {initialInspectionReports && initialInspectionReports.map((report) => 
                  <div><a href={`https://submityourclaim16ff83b99b1b4eeeb0d41905813e3f9d233037-staging.s3.us-west-2.amazonaws.com/public/${report}`} target="_blank" ><label>{report}</label></a><br/></div>
                
                )
                }

                <div>
                <label>Description of the claim you are filing:</label>
                <br></br>
                
                <label>{claimDescription}</label>
                </div>
                <div style={{alignItems:"center", display:"flex"}}>
                <Button onClick={() => closeClaimForm()}>Cancel</Button>
                <Button style={{margin:"5px"}} onClick={() => submitClaimAndClose()}>Retry Submission</Button>
                {claimNumber.startsWith('P') && 
                <TextField
                    id="rejectReason"
                    label="Reason for rejection"
                    
                    value={rejectReason}
                    onChange={(e) => setRejectReason(e.target.value)}
                    margin="normal"
                  />
              }
              {claimNumber.startsWith('P') && 
                <Button disabled={rejectReason.length === 0} onClick={()=>rejectClaimSubmittalAndClose()}>Reject Claim</Button>
              }
                </div>
                </Container>
                
              </Box>
            </div>
            
            
            
          </Modal>

          <Tabs>
            <TabList>
              <Tab>Pending Submitted Claims</Tab>
              <Tab>Active Submitted Claims</Tab>
            </TabList>

            <TabPanel>
                <div style={{ width: "100%", height: 400, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                <div className="ag-theme-quartz" style={{ height: 300, width: "70%" }}>
                  <AgGridReact rowData={unmatchedRowData} columnDefs={colDefs} overlayNoRowsTemplate='&nbsp' />
                </div>
                
              </div>
            </TabPanel>
            <TabPanel>
                <div style={{ width: "100%", height: 400, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                <div className="ag-theme-quartz" style={{ height: 300, width: "70%" }}>
                  <AgGridReact rowData={matchedRowData} columnDefs={colDefs} overlayNoRowsTemplate='&nbsp' />
                </div>
              </div>
            </TabPanel>
          </Tabs>
          
        </View>
      </View>


  );
}

const Header = () => <p>I am the header component</p>;

export default AdminApp;
